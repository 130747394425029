import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CryptoService } from '../_services/crypto.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  isLoading: boolean = false;
  isErrorService: boolean = false;
  constructor(
    private cs:CryptoService,
  ) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const user = this.cs.getCurrentUser()
    const userToken = user?.token;

    if (request.url.includes('solicitaPedido')) return next.handle(request.clone());
    const modifiedReq = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${userToken}`).set('Content-Type', 'application/json'),
    });

    return next.handle(modifiedReq);
  }

}