// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: {
      pedidos: 'https://api-dev.cardmenu.com.br/',
      gestor: 'https://apigestor-dev.cardmenu.com.br/',
  },
  firebase: {
    apiKey: "AIzaSyACDEqgz_X0eV0zNhQ5eTrdGUS8niVCheE",
    authDomain: "udicarte.firebaseapp.com",
    databaseURL: "https://udicarte.firebaseio.com",
    projectId: "udicarte",
    storageBucket: "udicarte.appspot.com",
    messagingSenderId: "853383689908",
    appId: "1:853383689908:web:91d4d82870ae72bd1109e1",
    measurementId: "G-X48SZK3RN3"
  },
  keys: {
    storage: "74b0LOWx",
    metodoDePagamento: '9cardapio7online8qeM3qWTpn7gbQe5nGmq4'
  },
  appInsights: {
    instrumentationKey: '8f652d82-def0-453e-9dfb-2e882d8f3b59'
  },
  version: require('../../package.json').version + '-beta',
  facebook: {
    appId: '284317262255495'
  },
  storageKey: "74b0LOWx",
  instrumentationKey: 'c3e5da37-fbe1-4261-84e4-3619e3c90ada',
  baseNumberOrder: 15012,
  defaultLogin: {
    username: "camilopinheiro@live.com",
    password: "senha1"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
