export enum AbertoFechadoAtendimentoEnum {
  ABERTO = "ABERTO",
  FECHADO = "FECHADO"
}

export enum BandeiraCartaoEnum {
  AMERICAN_EXPRESS = "American Express",
  DINERS = "Diners",
  ELO = "Elo",
  HIPERCARD = "Hipercard",
  HIPER = "Hiper",
  MASTERCARD = "Mastercard",
  VISA = "Visa"
}

export enum TipoCartaoEnum {
  DEBITO = "Debito",
  CREDITO = "Credito"
}

export enum TipoPagamentoEnum {
  DINHEIRO = "DINHEIRO",
  BOLETO = "BOLETO",
  CARTAO_CREDITO = "CARTAO_CREDITO",
  CARTAO_DEBITO = "CARTAO_DEBITO",
  PIX = "PIX",
  PAGAMENTO_ONLINE = "PAGAMENTO_ONLINE"
}


export enum DiaSemanaEnum {
  SEGUNDA_FEIRA = "Segunda-feira",
  TERCA_FEIRA = "Terça-feira",
  QUARTA_FEIRA = "Quarta-feira",
  QUINTA_FEIRA = "Quinta-feira",
  SEXTA_FEIRA = "Sexta-feira",
  SABADO = "Sabado",
  DOMINGO = "Domingo"
}
export enum OrdemCardapioEnum {
  ALFABETICO = "Alfabético",
  MAIOR_VALOR = "Maior valor",
  MENOR_VALOR = "Menor valor"
}
export enum ParametroSistemaEnum {
  SETUP_ENVIRONMENT = "SETUP_ENVIRONMENT",
  CLIENT_ID_GATEWAY = "CLIENT_ID_GATEWAY",
  SECRET_KEY_GATEWAY = "SECRET_KEY_GATEWAY",
  SELLER_ID_GATEWAY = "SELLER_ID_GATEWAY",
  ORGID_DEVICE_SANDBOX = "ORGID_DEVICE_SANDBOX",
  ORGID_DEVICE_PRODUCAO = "ORGID_DEVICE_PRODUCAO",
  TAXA_GETNET_CREDITO = "TAXA_GETNET_CREDITO",
  TAXA_GETNET_DEBITO = "TAXA_GETNET_DEBITO",
  TEMPO_DIAS_LIBERACAO_PAGAMENTO_GETNET = "TEMPO_DIAS_LIBERACAO_PAGAMENTO_GETNET",
  PARAR_TUDO = "PARAR_TUDO",
  PARAR_PAGAMENTO_ONLINE = "PARAR_PAGAMENTO_ONLINE",
  DISTANCIA_METROS_MAXIMA_ESTABELECIMENTOS_POR_LOCALIDADE = "DISTANCIA_METROS_MAXIMA_ESTABELECIMENTOS_POR_LOCALIDADE",
  QUANTIDADE_MAXIMA_ESTABELECIMENTOS_POR_LOCALIDADE = "QUANTIDADE_MAXIMA_ESTABELECIMENTOS_POR_LOCALIDADE",
  PORCENTAGEM_ERRO_DISTANCIA_CALCULO_FRETE = "PORCENTAGEM_ERRO_DISTANCIA_CALCULO_FRETE",//PADRAO � UNS 30%
  VALOR_MINIMO_FRETE = "VALOR_MINIMO_FRETE",
  TOKEN_REFRESH_TOKEN_IMGUR = "TOKEN_REFRESH_TOKEN_IMGUR",
  TOKEN_ACCESS_TOKEN_IMGUR = "TOKEN_ACCESS_TOKEN_IMGUR",
  ID_CLIENTE_IMGUR = "TOKEN_ACCESS_TOKEN_IMGUR",
  SECRET_CLIENTE_IMGUR = "TOKEN_ACCESS_TOKEN_IMGUR"
}

export enum StatusAtendimentoEnum {
  A_VALIDAR = "A_VALIDAR",
  ABERTO = "ABERTO",
  AGUARDANDO_PAGAMENTO = "AGUARDANDO_PAGAMENTO",
  AGUARDANDO_ENTREGA = "AGUARDANDO_ENTREGA",
  PAGO = "PAGO",
  CONCLUIDO = "CONCLUIDO",
  CANCELADO = "CANCELADO"
}

export enum StatusCobrancaEnum {
  ANDAMENTO = "ANDAMENTO",
  CANCELADO = "CANCELADO",
  CONCLUIDO = "CONCLUIDO"
}

export enum StatusPedidoEnum {
  //STATUS DO PEDIDO: ABERTO, EM PREPARO, PRONTO/PARA RETIRAR, FINALIZADO/ENTREGUE
  ABERTO = "ABERTO",
  EM_PREPARO = "EM_PREPARO",
  PRONTO_PARA_RETIRAR = "PRONTO_PARA_RETIRAR",
  CANCELADO = "CANCELADO",
  FINALIZADO_ENTREGUE = "FINALIZADO_ENTREGUE"
}

export enum TipoAtendimentoEnum {
  DELIVERY = "DELIVERY",//TIPO DE ATENDIMENTO ONDE QUE � FEITO PARA DELIVERY
  DEMANDA = "DEMANDA", //TIPO DE ATENDIMENTO ONDE QUE O CLIENTE JA SOLICITA O PEDIDO E JA PAGA
  COMANDA = "COMANDA" //TIPO DE ATENDIMENTO ONDE QUE O CLIENTE ABRE A COMANDA E SOLICITA O PEDIDO E PAGA SOMENTE NO FINAL DO ATENDIMENTO
}

export enum TipoDadosCheckoutEnum {
  DADOS_FRETE = "DADOS_FRETE",
  VALIDA_CUPOM_DESCONTO = "VALIDA_CUPOM_DESCONTO",
  METODO_PAGAMENTO_ACEITOS = "METODO_PAGAMENTO_ACEITOS"
}
export enum TipoDocumentoEnum {
  CPF = "CPF",
  CNPJ = "CNPJ"
}

export enum TipoEntregaEnum {
  ENTREGA = "Entrega",
  RETIRA_NA_LOJA = "Retira na loja"
}
export enum TipoFreteEnum {
  FRETE_QUILOMETRO = "Frete por quilômetro",
  FRETE_FIXO = "Frete fixo",
  FRETE_FIXO_POR_DISTANCIA = "Frete fixo por distância"
}
export enum TipoHistoricoTransacoesEnum {
  TRANSACAO = "TRANSACAO"
}

export enum TipoProdutoEnum {
  NORMAL = "NORMAL",
  VARIOS_SABORES_MAIS_CARO = "VARIOS_SABORES_MAIS_CARO"
}


export enum TipoVerificacaoMinimoMaximoEnum {
  POR_COMANDA = "POR_COMANDA",
  POR_PEDIDO = "POR_PEDIDO"
}

export enum OperacoesCaixa {
  INSERCAO = "INSERCAO",
  RETIRADA = "RETIRADA",
  ABERTURA = "ABERTURA",
  FECHAMENTO = "FECHAMENTO",
  SANGRIA = "SANGRIA"
}

export enum StatusCaixa {
  ABERTO = "ABERTO",
  FECHADO = "FECHADO"
}

export enum TipoEmpresaEnum{
  MEI ="Micro Empreendedor Individual",
  LIMITED = "LTDA - Empresa Limitada",
  INDIVIDUAL = "Empresa individual",
  ASSOCIATION = "Associação"
}


export enum TipoInscricaoEstadualEnum{
  CONTRIBUINTE = 'Contribuinte',
  NAO_CONTRIBUINTE = 'Não contribuinte',
  CONTRIBUINTE_ISENTO= 'Contribuinte isento'
}


export enum TipoAlertaEnum{
  FRACO = 'Fraco',
  FORTE = 'Forte'
}

export enum TipoUsuarioEnum {
  ADMINISTRADOR = "ADMINISTRADOR",
  ASSINANTE = "ASSINANTE",
  SUB_USUARIO = "SUB_USUARIO",
  CLIENTE = "CLIENTE"
}

export enum TipoAcessoEnum {
  LOJISTA_ADMINISTRADOR = "LOJISTA_ADMINISTRADOR",
  LOJISTA_COLABORADOR = "LOJISTA_COLABORADOR",
  LOJISTA_GERENTE = "LOJISTA_GERENTE",
}
