<dropzone [config]="config" *ngIf="!linkImagem" [message]="'Clique ou arraste uma imagem para enviar'"
  (error)="onUploadError($event)"
  (success)="onUploadSuccess($event)"></dropzone>

<div *ngIf="linkImagem" class="d-flex position-relative justify-content-center p-2"
  style="height: 11em; overflow: hidden;">
  <div class="card w-100" style="background-image: url('{{linkImagem}}'); height: 10em;  background-size: contain; 
  height: 10em;
  background-position: center;
  background-repeat: no-repeat;"></div>
  <button type="button" class="btn btn-rounded btn-outline-default  border-0" (click)="removeImage()"
    style="position: absolute; bottom: 0; right: 2em;">
    <i class="fal fa-trash text-primary"
      style="cursor: pointer;"></i>
  </button>
</div>