import { registerLocaleData } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import localePt from '@angular/common/locales/pt';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from '@coturiv/ngx-loading';
import { NgSelectModule } from "@ng-select/ng-select";
import { ModalModule } from 'ngx-bootstrap/modal';
import { DropzoneConfigInterface, DropzoneModule, DROPZONE_CONFIG } from "ngx-dropzone-wrapper";
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { MomentModule } from 'ngx-moment';
import { RestangularModule } from 'ngx-restangular';
import { ToastrModule, ToastrService } from "ngx-toastr";
import { TourModule } from "ngx-ui-tour-core";
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared.module';
import { RequestInterceptor } from "./_helpers/requestinterceptor";
import { AuthenticationService } from "./_services/authentication.service";

registerLocaleData(localePt);
registerLocaleData(localePt, "pt");


const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  clickable: true,
  url: 'https://api.imgbb.com/1/upload?key=a1537a3d7fdc36ad33483084f803bb54',
  paramName: 'image',
  acceptedFiles: '.jpeg,.jpg,.png,.gif',
  timeout: 0,

  method: 'post',
  headers: {
    'Cache-Control': null,
    'X-Requested-With': null,
    "contentType": false,
    "timeout": 0,
    "processData": false
  },
  maxFilesize: 50,
};

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

export function RestangularConfigFactory(RestangularProvider, toastService, authService) {
  RestangularProvider.setDefaultHttpFields({ cache: true });
  let token = localStorage.getItem('token');
  //    const waitFor = delay => new Promise(resolve => setTimeout(resolve, delay));
  //  await waitFor(5000);
  RestangularProvider.setBaseUrl(environment.apiUrl.gestor).addRequestInterceptor(function (elem, operation, what, url) {
    if (operation === 'remove') {
      return null;
    }
    return elem;
  }).setDefaultHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + token });
  RestangularProvider.addFullRequestInterceptor((element, operation, path, url, headers, params) => {
    return {
      params: Object.assign({}, params, {}),
    };
  });

  RestangularProvider.addErrorInterceptor(async (response, subject, responseHandler) => {
    //   monitoramentoService.logException(response.error.message, 1)

    if (response.status !== 401) {
      //  showError(response.error.message, ToastController, this);
    }
    if (response.status == 401 && localStorage.getItem('token')) {
      authService.SignOut();
      return true;
    }
    else
      if (!environment.production)
        await toastService.error(response.error.message || 'Algo de errado aconteceu')
  });
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    ToastrModule.forRoot(),
    FormsModule,
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        'm': 59,
      }
    }),
    NgxLoadingModule.forRoot({
      fullScreenBackdrop: true, animationType: ngxLoadingAnimationTypes.circle,
      primaryColour: '#ccc',
      secondaryColour: '#fff',
    }),
    AppRoutingModule,
    TourModule.forRoot(),
    DropzoneModule,
    HttpClientModule,
    NgSelectModule,
    AngularFireModule.initializeApp(environment.firebase),
    RestangularModule.forRoot([ToastrService, AuthenticationService], RestangularConfigFactory),
    ModalModule.forRoot(),
    NgxMaskModule.forRoot(),
    ServiceWorkerModule.register('combined-sw.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately'
    }),
    BrowserAnimationsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: DROPZONE_CONFIG, useValue: DEFAULT_DROPZONE_CONFIG }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


