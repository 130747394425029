import { Component, Input, OnInit } from '@angular/core';
import { Atendimento } from 'src/app/_models/Atendimento';
import { Estabelecimento } from 'src/app/_models/Estabelecimento';
import { AtendimentoService } from 'src/app/_services/atendimento.service';
import { CryptoService } from 'src/app/_services/crypto.service';
import { SharedService } from 'src/app/_services/shared.service';
import { TipoAtendimentoEnum } from 'src/app/_helpers/enums';
import { EnderecoService } from 'src/app/_services/endereco.service';
import { UsuarioStorage } from 'src/app/_dto/UsuarioStorage';
import { HttpErrorResponse } from '@angular/common/http';
import { Endereco } from 'src/app/_models/Endereco';
import { MaskPipe } from 'ngx-mask';
import { BsModalService } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-impressao',
  templateUrl: './impressao.component.html',
  styleUrls: ['./impressao.component.scss'],
})
export class ImpressaoComponent implements OnInit {

  @Input() atendimento:Atendimento;
  public loadingEndereco:boolean=false;

  public currentUser:UsuarioStorage;
  public estabelecimento: Estabelecimento = JSON.parse(localStorage.getItem('estabelecimento'));

  constructor(
    private cryptoService:CryptoService,
    private enderecoService:EnderecoService,
    private sharedService: SharedService,
    private modalService:BsModalService
    ) { 
      this.atendimento = new Atendimento();
    }

  ngOnInit() {
    if(this.atendimento?.idAtendimento){
      console.log(this.atendimento);
      if(this.atendimento.tipoAtendimento == TipoAtendimentoEnum.DELIVERY){
        this.carregarEndereco();
      }
      this.currentUser = this.cryptoService.getCurrentUser();
    }
  }

  carregarEndereco(){
    this.loadingEndereco=true;
    this.enderecoService.findEnderecoAtendimento(this.atendimento.idAtendimento).subscribe((res:Endereco)=>{
      this.atendimento.endereco = res;
      this.loadingEndereco=false;
    },(err:HttpErrorResponse)=>{
      console.log(err.error.message || err.message);
      this.loadingEndereco=false;
      this.sharedService.show('Falha ao buscar endereço do atendimento.','error');
    });
  }

  closeModal(){
    this.modalService.hide();
  }

  p() {
    const framePrint = document.createElement('iframe');
    const printContent = document.getElementById('print-section');
    var content = '';
    
    if(framePrint){
      document.body.appendChild(framePrint);
      const doc:any = (framePrint.contentWindow || framePrint.contentDocument);
      doc.document.open();
      var style = `
          
        .rodape-aviso{
          font-size: .8em;
        }

        hr{
          width: 100%;
          border-bottom: 1px solid #000;
        }

        .rodape-imprimir{
          text-align: center;
        }

        .margem-sm{
          margin: 3px 0px 0px 0px;
        }

        .margem-lg{
          margin: 8px 0px 0px 0px;
        }

        .imprimir-dados-texto{
          margin: 3px 0px 0px 0px; 
          text-align: left;
        }

        .imprimir-dados-texto-valores{
          text-align: right;
        }

        .logo-imprimir{
          text-align: center;
        }

        .logo-imprimir svg{
          display: inline-block;
          vertical-align: middle;
        }
        .logo-imprimir strong{
          display: inline-block;
          vertical-align: middle;
        }

        .pedido-imprimir p{
          margin-bottom:0px;
        }

        .pedido-imprimir-title{
          display: block;
          width: 100%;
        }

        .pedido-id{
          display: inline-block;
          width: 20%;
          vertical-align: middle;
        }
        .pedido-qtd{
          display: inline-block;
          width: 15%;
          vertical-align: middle;
        }
        .pedido-produto{
          display: inline-block;
          width:40%;
          vertical-align: middle;
        }
        .pedido-valor{
          display: inline-block;
          width: 25%;
          vertical-align: middle;
        }

        .pedido-imprimir-filhos{
          display: block;
          width: 100%;
        }

        .pedido-obs{
          color:#575757;
        }`;
      content += '<head>';
      content += '<style>'+style+'</style>';
      content += '</head><body onload="this.focus(); this.print();">';
      content += '<div style="margin: 0; 	font-family: Arial,Helvetica Neue,Helvetica,sans-serif;  font-size: 10px;>';
      content += printContent.innerHTML;
      content += '</div>';
      content += '</body>';
      doc.document.write(content);
      doc.document.close();
      setTimeout(()=>{
        document.body.removeChild(framePrint);
      },300);
    }
  }


}
