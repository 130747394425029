import { HttpClient } from "@angular/common/http";
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import { Restangular } from 'ngx-restangular';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SharedService } from './_services/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'cardmenu';
  production = environment.production;
  isMobile;
  sidebarMinified = localStorage.getItem('sidebarMinified') == "true";
  constructor(private router: Router, private ss: SharedService, private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private restangular: Restangular, private titleService: Title) {

      this.cleanLocalStorageOnce();


    this.isMobile = /Android|iPhone/i.test(window.navigator.userAgent);
    moment.locale('pt-BR');
    if (!this.isMobile)
      this.ss.getSidebarMinified().subscribe(a => {
        this.sidebarMinified = !this.sidebarMinified;
        localStorage.setItem('sidebarMinified', String(this.sidebarMinified));
      })
    else this.sidebarMinified = false;
    if (this.isMobile) {
      if (localStorage.getItem('logado'))
        this.router.navigate(['/estabelecimento']);
      localStorage.removeItem('appSidebarMinified');
    }
    // this.httpClient.get(environment.apiUrl.gestor).subscribe(console.log);
    localStorage.setItem('modulos', JSON.stringify(["CAIXA", "PDV"]));




    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data && child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          } else {
            return null;
          }
        }
        return null;
      })
    ).subscribe((data: any) => {
      if (data) {
        this.titleService.setTitle(data + ' - Cardmenu');
      }
    });
  }


  private hasCleanedLocalStorageKey = 'hasCleanedLocalStorage';

  cleanLocalStorageOnce() {
    const hasCleanedLocalStorage = localStorage.getItem(this.hasCleanedLocalStorageKey);

    if (!hasCleanedLocalStorage) {
      localStorage.clear();
      localStorage.setItem(this.hasCleanedLocalStorageKey, 'true');
    }
  }

}
