import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ConfiguracoesUsuario } from '../_dto/ConfiguracoesUsuario';
import { EstabelecimentoFirestore } from '../_dto/FirestoreDocs';
import { firestoreService } from './firestore.service';
type toastrColor = 'success' | 'error' | 'warning' | 'info';
import { Howl } from 'howler';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  sidebarMinified = new Subject();
  statusLogin = new Subject();
  modalCanSwipe = new Subject();
  subjectUpload = new Subject();
  subjectPerfilUpload = new Subject();
  idEstabelecimentoAtual:number;
  private estabelecimentoAtual = new BehaviorSubject((localStorage.getItem('estabelecimento') ? JSON.parse(localStorage.getItem('estabelecimento')) : null));
  private communicate = {};
  public atualizouPerfilUsuario = new Subject();
  private subsCriptionPagamento:Subscription;

  

  constructor(private http: HttpClient, private toast: ToastrService, private fs:firestoreService) { 
    this.idEstabelecimentoAtual = JSON.parse(localStorage.getItem('estabelecimento'))?.idEstabelecimento;
    this.atualizouPerfilUsuario.subscribe((res)=>{
      if(this.subsCriptionPagamento) this.subsCriptionPagamento.unsubscribe();
      this.verificaPagamentos();
    })
  }

  getSidebarMinified() {
    return this.sidebarMinified.asObservable();
  }

  setUrlUpload(url: string) {
    this.subjectUpload.next(url);
  }
  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subjectUpload.next(null);
    this.subjectUpload.complete();
  }

  getUrlUpload(): Observable<any> {
    return this.subjectUpload.asObservable();
  }

  toggleSidebarMinified() {
    this.sidebarMinified.next()
  }


  store(key, value, type?: "ADD" | "SET") {
    const t = (localStorage.getItem(key)) ? JSON.parse(localStorage.getItem(key)) : [];
    localStorage.setItem(key, JSON.stringify((type == 'ADD') ? [...t, value] : t));
  }

  get(key) {
    const t = localStorage.getItem(key);
    if (t) return JSON.parse(t);
    else return false;
  }

  setEstabelecimentoAtual(estabelecimento) {
    this.estabelecimentoAtual.next(estabelecimento);
  }

  getEstabelecimentoAtual(): Observable<any> {
    return this.estabelecimentoAtual.asObservable();
  }

  sendMessage(key, message) {
    if (this.communicate[key]) this.communicate[key]?.next(message);
    else
      this.communicate[key] = new ReplaySubject(message);
  }

  getMessage(key): Observable<any> {
    this.communicate[key] = this.communicate[key] || new ReplaySubject();
    return this.communicate[key]?.asObservable();
  }


  getIp() {
    return this.http.get("//api.ipify.org/?format=json");
  }

  show(message, color?: toastrColor, timeout?) {
    switch (color) {
      case ('success'):
        this.toast.success(message, '', { timeOut: timeout || 3000, positionClass: 'toast-top-center' })
        break;
      case ('error'):
        this.toast.error(message, '', { timeOut: timeout || 3000, positionClass: 'toast-top-center' })
        break;
      case ('warning'):
        this.toast.warning(message, '', { timeOut: timeout || 3000, positionClass: 'toast-top-center' })
        break;
      case ('info'):
        this.toast.info(message, '', { timeOut: timeout || 3000, positionClass: 'toast-top-center' })
        break;
      default:
        this.toast.show(message, '', { timeOut: timeout || 3000, positionClass: 'toast-top-center' })
        break;
    }
  }

  delete(securityLevel: 0 | 1 | 2, placeholder?) {
    return new Promise((res, rej) => {
      if (securityLevel <= 0)
        res(true);
      else {
        Swal.fire({
          title: "Tem certeza?",
          html: (securityLevel > 1) ? `Para confirmar a exclusão, digite <b><u>${placeholder}</u></b> no campo abaixo` : 'Os dados podem não ser recuperados',
          icon: "warning",
          showLoaderOnConfirm: true,
          inputPlaceholder: placeholder,
          input: (securityLevel > 1) ? "text" : null,
          showCancelButton: true,
          cancelButtonText: "Desisto",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Remover",
          preConfirm: v => {
            if (securityLevel > 1 && v.toString()?.trim() != placeholder?.toString()?.trim())
              return Swal.showValidationMessage(`Digite corretamente`);
            else
              res(true)
          },
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.value != null) {
          }
        })
      }
    })
  }


  async confirmacaoAlert(message: string) {
    const { value } = await Swal.fire({
      title: message,
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonText: "Sim",
      allowOutsideClick: () => !Swal.isLoading(),
      backdrop: true,
    });
    if (value != null) {
      return true;
    }
    else {
      return false;
    }
  }

   async informacaoAlert(message: string) {
    await Swal.fire({
      title: message,
      icon:'info',
      showCancelButton: false,
      confirmButtonText: "OK",
      allowOutsideClick: () => !Swal.isLoading(),
      backdrop: true,
    });
  }

  async confirmacaoAlertHtml(html: string) {
    const { value } = await Swal.fire({
      html: html,
      showCancelButton: false,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Ok",
      allowOutsideClick: () => !Swal.isLoading(),
      backdrop: true,
      width:600
    });
    if (value != null) {
      return true;
    }
    else {
      return false;
    }
  }


  async copyAlert(message:string, copyValue: string){
    await Swal.fire({
      title: message,
      showCancelButton: false,
      confirmButtonText: "<i class='far fa-copy'></i>",
      allowOutsideClick: () => !Swal.isLoading(),
      backdrop: true,
    }).then(result=>{
      if(result.isConfirmed){
        this.messageToaster('Senha copiada com sucesso!', 'success');
        navigator.clipboard.writeText(copyValue);
      }
    });
  }


  formatStringDate(date:Date):string{
    let dateStr = date.toString().split('T')[0];
    return dateStr;
  }


  messageToaster(message:string, icon:toastrColor, timeout?:number){
    var backgroundColor:string;
    if(icon=='success'){
      backgroundColor='#48b54a';
    }else if(icon=='error'){
      backgroundColor='#b54848';
    }else if(icon=='info'){
      backgroundColor='#4898b5';
    }else if(icon=='warning'){
      backgroundColor='#b59c48';
    }
    Swal.fire({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: timeout || 3000,
      icon: icon,
      iconColor: '#f5f5f5',
      color: '#f5f5f5',
      background: backgroundColor || '#5c5c5c',
      title: message
    });
  }

  codigosPaises(){
    return this.http.get('https://restcountries.com/v2/all').pipe(map((res: any) => res.filter(r => r?.callingCodes[0]).map(r => {
      return { name: r.name, callingCode: r.callingCodes[0] };
    })));
  }

  verificaPagamentos(){
    var configuracoesUsuario:ConfiguracoesUsuario = JSON.parse(localStorage.getItem(btoa('ConfiguracoesUsuario')))
    if(configuracoesUsuario?.mensagemAutomaticaPagamento){
      this.subsCriptionPagamento = this.fs.docWithRefs$('ESTABELECIMENTOS/'+this.idEstabelecimentoAtual).subscribe((res:EstabelecimentoFirestore)=>{
        if(res.novos_pagamentos?.length>0){
          let message:string='';
          message+=`
          <h3>Você recebeu novos pagamentos!</h3>
          `
          res.novos_pagamentos.map(res=>{
            let dataCreatedAt = new Date(res?.createdAt?.seconds * 1000);
            message+=`
            <p class="mb-0"> <strong>Atendimento #${res.id_atendimento}</strong></p>
            <p class="mb-0"> <strong>Status:</strong>${res.status}</p>
            <p class="mb-2"> <strong>Data/hora:</strong>${dataCreatedAt.toLocaleString()}</p>
            <hr class="w-75 m-auto text-center">
            `
          })
          this.confirmacaoAlertHtml(message);
          res.novos_pagamentos = null;
          setTimeout(()=>{
            this.fs.upsert('ESTABELECIMENTOS/'+this.idEstabelecimentoAtual, res);
          },3000)
        }
      });
    }
  }

  notificarNavegador(){
    var configuracoesUsuario:ConfiguracoesUsuario = JSON.parse(localStorage.getItem(btoa('ConfiguracoesUsuario')))
    if(configuracoesUsuario?.mensagemAutomaticaAtendimento){
      var sound = new Howl({
        src: ['assets/'+configuracoesUsuario.somAlerta+'.mp3']
      });
      sound.play();
    }
  }

  validaDatasInicialFinal(dataInicial:string, dataFinal:string):boolean{
    if(!dataInicial || !dataFinal ){
      this.show('Datas inválidas','error');
      return false;
    }
    var dataIni = new Date(dataInicial);
    var dataFim = new Date(dataFinal);
    if(dataFim<dataIni){
      this.show('Datas final menor que data inicial','warning');
      return false;
    }
    return true;
  }

}
