import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import CryptoJS from "crypto-js";
import { UsuarioStorage } from '../_dto/UsuarioStorage';


@Injectable({
  providedIn: 'root'
})

export class CryptoService {

  constructor() { }


  getCurrentUser(): UsuarioStorage{
    if (localStorage.getItem(btoa("user"))) {
        return JSON.parse(
            CryptoJS.AES.decrypt(
                localStorage.getItem(btoa("user")),
                environment.keys.storage
            ).toString(CryptoJS.enc.Utf8)
        );
    }
    return null;
}

}
