import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PdvComponent } from './dashboard/atendimento/pdv/pdv.component';
import { AuthGuard } from './_guards/auth.guard';

const routes: Routes = [{
  path: '',
  canActivate: [AuthGuard],
  loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
},
{
  path: 'acesso',
  loadChildren: () => import('./acesso/acesso.module').then(m => m.AcessoModule)
},
{ path: 'demo', redirectTo: '/acesso/demo' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, anchorScrolling: 'enabled', onSameUrlNavigation: 'reload', scrollOffset: [100, 1] })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
