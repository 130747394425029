<router-outlet></router-outlet>
<p style="  
top: 8px;
    text-align: center;
    left: 23%;
    background-color: red;
    z-index: 10000;
    color: white;
    border: 4px solid black;
    padding: 5px 15px;
    position: absolute;
    border-radius: 14px;
    font-size: 13px;" *ngIf="!production">Modo dev</p>