import { Pessoa } from "./Pessoa";

export class Usuario{
    idPessoa:number;
    idUsuario:number;
    idEstabelecimento:number;
    tipoUsuario:string;
    email:string;
    senha:string;
    tokenPush:string;
    tokenPushPortal:string;
    idCustomerAsaas:string;
    uuid:string;
    uuidAparelho:string;
    linkImagem:string;
    dataUltimoLogin:string;
    statusNovo:boolean;
    status:boolean;
    pessoa:Pessoa;

    tipoAcessoAtual?:string;
}