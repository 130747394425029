import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { ImpressaoComponent } from './_components/impressao/impressao.component';
import { MeuDropzoneComponent } from './_components/meudropzone.component';
import { ExibirEnumPipe } from './_helpers/exibir-enum.pipe';
import { MaskPipe, NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [MeuDropzoneComponent, ImpressaoComponent, ExibirEnumPipe],
  imports: [
    AngularFireStorageModule,
    CommonModule,
    DropzoneModule,
    NgxMaskModule
  ], exports: [MeuDropzoneComponent, ImpressaoComponent,ExibirEnumPipe],
  providers:[MaskPipe]
})
export class SharedModule { }
