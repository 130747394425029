import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { AngularFireAuth } from '@angular/fire/compat/auth';
import CryptoJS from "crypto-js";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Usuario } from '../_models/Usuario';
import { UsuarioStorage } from '../_dto/UsuarioStorage';
import { CryptoService } from './crypto.service';
import { firestoreService } from "./firestore.service";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  userData;
  $authState = new BehaviorSubject(null);
  constructor(
    public fs: firestoreService,
    public afAuth: AngularFireAuth,
    private cs:CryptoService,
    private http: HttpClient
  ) {


  }

  loginApiPedidos() {
    return new Promise((resolve, rej) => {
      this.afAuth.signInAnonymously().then(r => {
        const usert = JSON.parse(JSON.stringify(r.user));
        this.http.post(environment.apiUrl.pedidos + 'login', { uuid: usert.uid || usert.id }).subscribe((res: any) => {
          if (res.token) {
            localStorage.setItem(
              "tokenPedidos", res.token,
            );
            resolve(res.token);
          }
        });
      });
    });
  }

  login(email: string, senha: string) {
    this.loginApiPedidos();
   
    return this.http.post<UsuarioStorage>(`${environment.apiUrl.gestor}login`, { email, senha })
      .pipe(map((user:UsuarioStorage) => {
        this.setCurrentUser(user);
        location.reload();
        return user;
      }));
  }




  setCurrentUser(user:UsuarioStorage) {
    console.log(user);
    if (!user) return;
    if (user.token) {
      console.log(user.token);
      this.$authState.next(user);
      localStorage.setItem(
        btoa("user"),
        CryptoJS.AES.encrypt(
          JSON.stringify(user),
          environment.keys.storage
        ).toString()
      );
      localStorage.setItem(
        "token", user.token,
      );
    }
  }


  estaLogado(): boolean {
    const user = this.cs.getCurrentUser();
    return user !== null || (user && user.emailVerified !== false)
      ? true
      : false;
    return user !== null && user.emailVerified !== false ? true : false;
  }

  get isAdmin(): boolean {
    const user = this.cs.getCurrentUser();
    return user && user.is_admin === 1 ? true : false;
    return user !== null && user.emailVerified !== false ? true : false;
  }

  authState(): Observable<any> {
    return this.$authState as Observable<any>;
  }


  SignOut() {
    localStorage.removeItem(btoa("user"));
    localStorage.removeItem("token");
    localStorage.removeItem("tokenPedidos");
    localStorage.removeItem("modulos");
    localStorage.removeItem("sidebarMinifed");
    localStorage.removeItem("estabelecimento");
    localStorage.clear();
    window.location.href = '/'
    let t = [];
    if ((<any>window).ngxOnesignal)
      setTimeout(() => {
       console.log('limpando tags');
        (<any>window).ngxOnesignal.push([
          "getTags",
          function (result) {
            (<any>window).ngxOnesignal.push([
              "deleteTags", Object.keys(result),
              function (result) {
              }
            ]);
           console.log(t)
          }
        ]);
      }, 3000);
  }

  storageUserToUser(usuarioStorage:UsuarioStorage):Usuario{
    let usuario = new Usuario();
    usuario.idUsuario = usuarioStorage.idUsuario;
    usuario.idPessoa = usuarioStorage.idPessoa;
    usuario.email = usuarioStorage.email;
    usuario.uuid = usuarioStorage.uuid;
    usuario.tipoUsuario = usuarioStorage.tipoUsuario;
    usuario.tipoAcessoAtual = usuarioStorage.tipoAcessoAtual;
    return usuario;
  }


}



function success(arg0: string, success: any) {
  throw new Error('Function not implemented.');
}

