import { ItemCarrinho } from '../_dto/itemCarrinho';
import { TipoAtendimentoEnum, TipoProdutoEnum } from '../_helpers/enums';
import { Cobranca } from './Cobranca';
import { Endereco } from './Endereco';
import { Mesa } from './Mesa';
import { Pedido } from './Pedido';
import { Usuario } from './Usuario';

export class Atendimento {

    //atributos comuns
    idAtendimento: number;
    idEstabelecimento: number;
    idMesa: number;
    idUsuario: number;
    idEndereco: number;
    idCupomDesconto: number;
    idMetodoPagamento: number;
    idUsuarioResponsavel:number;
    dataAtendimentoInicial: string;
    dataAtendimentoFinal: string;
    mesAtendimento: number;
    anoAtendimento: number;
    tipoAtendimento: any;
    descricaoAdicional: string;
    qtdItens: number;
    valor:number;
    valorDesconto: number;
    valorGorjeta: number;
    valorCover: number;
    valorAdicional: number;
    valorTroco: number;
    valorFrete: number;
    contaAberta: boolean;
    dataUltimoPedido: string;
    dataPedidoNovo: string;
    hashAtendimento:string;
    dataHashAtendimento: string;
    avaliacaoAtendimento: number;
    statusPagamentoEntrega: boolean;
    statusNaoPossuiCartao: boolean;
    statusPedidoNovo: string;
    statusAtendimento: string;
    status: boolean;
    flagCobrancaAvulso: boolean;
    nomePessoa?: string;
    nomeResponsavel?: string;
    telefone1Pessoa?:string;
    telefone2Pessoa?:string;
    //atributos Objetos
    endereco?: Endereco;
    listProdutoEntity?: [ItemCarrinho] = null;
    pedidos?: Pedido[] = null;
    mesa?: Mesa = null;
    usuario?: Usuario;
    cobrancas?:Cobranca[] = null;
    //atributos ENUM
    tipoAtendimentoEnum?: TipoAtendimentoEnum;
    tipoPagamentoEnum: string;
    tipoEntregaEnum?: 'ENTREGA' | 'RETIRA_NA_LOJA';
    tipoProdutoEnum?: TipoProdutoEnum;

    

    //atributos front - Atributos criados para o atendimento ser exibido no front
    idAtendente?: number;
    idCartao?: number;
    ip?: string;
    obs?: string;
    pedidoAberto?: false;
    possuiAgregado?: false;
    statusPedido?: string;
    troco?: number;
    pagamentoNaEntrega?: boolean;
    valorTotal: number;
    necessitaValidacao?: any;
    cupom?: string;
    metodoPagamento?: any;
    selecionada?: boolean; //campo para controle de comanda selecionada na tela de comandas
    qtdeNovos?:number; //campo para tela de atendimentos, contagem de novos pedidos
    busca?:string; // campo para controle da barra de busca na tela de pedidos
    chatAberto?:boolean //campo para controlar abertura e fechamento do componente de chat
    novasMensagens?:number //campo para controle da quantidade de mensagens na tela
    dataAtualizacaoAtendimento?:Date; //utlima atualizacao que o atendimento sofreu
    dataAtualizacaoPedidos?:Date; //ultima atualizacao que os pedidos desse atendimento sofreram
    novoAtendimento?:boolean;
    estatisticas?:{
        aberto:number,
        cancelado:number,
        emPreparo:number,
        finalizadoEntregue:number,
        prontoParaRetirar:number
    }

}
