import { Pipe, PipeTransform } from '@angular/core';
type textFormat = 'titlecase' | 'uppercase';

@Pipe({
  name: 'exibirEnum'
})
export class ExibirEnumPipe implements PipeTransform {

  transform(value: string, format: textFormat): string {
    var formatado = value;
    if(formatado?.length==0){
      return '';
    }

    //tratamento 1
    var palavras:string[] = formatado.split('_');

    //formatando titlecase
    if(palavras?.length>1){
      if(format=='titlecase'){
        palavras = palavras.map(palavra=>{
          palavra=palavra.charAt(0).toUpperCase()+palavra.substring(1,palavra.length).toLowerCase();
          return palavra;
        });
      }else if(format=='uppercase'){
        palavras = palavras.map(palavra=>{
          return palavra.toUpperCase();
        });
      }
      formatado = palavras.join(' ');
    }else{
      if(format=='titlecase'){
        formatado = formatado.charAt(0).toUpperCase()+formatado.substring(1,formatado.length).toLowerCase()
      }else if(format=='uppercase'){
        formatado = formatado.toUpperCase();
      }
    }
    
    return formatado;
  }

}
