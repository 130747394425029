import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { DropzoneComponent, DropzoneConfigInterface, DropzoneDirective } from 'ngx-dropzone-wrapper';
import { SharedService } from '../_services/shared.service';

@Component({
  selector: 'app-meudropzone',
  templateUrl: './meudropzone.component.html',
})
export class MeuDropzoneComponent implements OnInit {

  files = []
  public config: DropzoneConfigInterface = {
    clickable: true, maxFiles: 1, autoReset: null, errorReset: null, cancelReset: null
  };

  @Input() linkImagem: any;
  @ViewChild(DropzoneComponent) componentRef?: DropzoneComponent;
  @ViewChild(DropzoneDirective) directiveRef?: DropzoneDirective;
  constructor(
    private afStorage: AngularFireStorage,
    private sharedService: SharedService,
  ) { }
  ngOnInit() {
    
  }



  resetDropzone() {
    this.sharedService.setUrlUpload(``);
    this.componentRef.directiveRef.reset();
  }

  public onUploadError(args: any): void {
  }

  public onUploadSuccess(args: any): void {
    console.log(args)
    this.sharedService.setUrlUpload(args[1]['data']['url'])
  }

  removeImage() {
    this.linkImagem = "";
    this.sharedService.setUrlUpload(this.linkImagem);
  }

}
