import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { Observable } from 'rxjs';
import { Endereco } from '../_models/Endereco';


@Injectable({
  providedIn: 'root'
})
export class EnderecoService {

  constructor(
    private restangular:Restangular
  ) { }



  findEnderecoAtendimento(idAtendimento:number):Observable<Endereco>{
    return this.restangular.one('atendimentos', idAtendimento).one('endereco').get();
  }

  salvarEnderecoEstabelecimento(idEstabelecimento:number, endereco:Endereco){
    return this.restangular.one('estabelecimentos', idEstabelecimento).one('atualizarEndereco').customPUT(endereco);
  }
}
