
<div class="modal-header">
  <h4 class="modal-title pull-left">Imprimir Atendimento - #{{atendimento.idAtendimento}}</h4>
  <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body bg-dark p-2 modal-imprimir-body">
  <div class="row d-flex justify-content-center">
    <div class="imprimir-papel" id="print-section" style="font-size: 10px">
      <p class="logo-imprimir">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="20.000000pt" height="20.000000pt" viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet">
          <metadata>
            Created by potrace 1.16, written by Peter Selinger 2001-2019
          </metadata>
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#000000" stroke="none">
          <path d="M60 2560 l0 -2480 2500 0 2500 0 0 2480 0 2480 -2500 0 -2500 0 0
          -2480z m2897 1590 c186 -5 356 -10 379 -10 55 0 67 -26 60 -129 -3 -42 -10
          -517 -16 -1056 -11 -992 -17 -1265 -29 -1265 -3 0 -191 551 -416 1223 -365
          1090 -408 1226 -396 1241 11 13 23 16 47 11 18 -3 185 -10 371 -15z m-754 -57
          c8 -10 223 -634 477 -1388 371 -1104 459 -1374 451 -1391 -8 -18 -118 -58
          -635 -230 -624 -208 -626 -209 -645 -189 -13 13 -191 529 -481 1390 -371 1103
          -459 1374 -451 1391 8 18 117 58 623 227 337 113 620 206 629 206 9 1 23 -7
          32 -16z m1751 -343 c121 -39 226 -72 232 -74 7 -3 -4 -52 -32 -138 -23 -73
          -135 -430 -249 -793 -113 -363 -210 -669 -214 -680 -6 -12 -7 36 -5 120 8 248
          34 1417 34 1528 0 59 3 107 6 107 4 0 106 -32 228 -70z"/>
          </g>
        </svg>
          &nbsp;
          &nbsp;
        <strong style="font-size: 15px">CARDMENU</strong> 
      </p>
      <hr class="margem-lg">
    
      <!-- Dados estabelecimento -->
      <p class="imprimir-dados-texto"> 
        <strong>Estabelecimento:</strong>{{estabelecimento?.fantasia}}
        <br>
        <span *ngIf="estabelecimento?.cpfCnpj">
          <strong>CPF/CNPJ:</strong>  {{estabelecimento?.cpfCnpj}}
        </span>
      </p>
    
      <!-- Dados Atendimento -->
      <p class="imprimir-dados-texto"> 
        <strong>Atendimento: #{{atendimento?.idAtendimento}}</strong>  
        <br>
        <span style="right: 0">
          <strong>Data: </strong>{{atendimento?.dataAtendimentoInicial | date: 'YY/MM/dd - HH:mm:ss':'UTC'}}
        </span>
        <br>
        <span *ngIf="atendimento?.tipoAtendimento">
          <strong>Tipo atendimento: </strong> {{atendimento?.tipoAtendimento.split('_').join(' ') | titlecase}}
        </span>
      </p>
      
      <!-- Dados Mesa -->
      <p  *ngIf="atendimento?.idMesa" class="imprimir-dados-texto">
        <strong>Mesa: </strong>{{atendimento?.idMesa}}
      </p>
    
      <!-- Dados Cliente - Pedidos com id usuario -->
      <p *ngIf="atendimento?.usuario?.pessoa != null" class="imprimir-dados-texto">
        <span *ngIf="atendimento?.usuario?.pessoa?.nome">
          <strong>Cliente: </strong>{{atendimento?.usuario.pessoa.nome}}
        </span>
        <br>
        <span *ngIf="atendimento?.usuario?.pessoa?.telefone1">
          <strong>Telefone: </strong>{{atendimento?.usuario?.pessoa.telefone1}} &nbsp; 
          <span *ngIf="atendimento?.usuario?.pessoa.telefone2">
            / &nbsp; {{atendimento?.usuario?.pessoa.telefone2}}
          </span>
        </span>
        <br>
        <span *ngIf="atendimento?.usuario?.pessoa?.cpf">
          <strong>CPF cliente: </strong>{{atendimento?.usuario?.pessoa.cpf}}
        </span>
      </p>

    
      <!-- Dados Endereco - Pedidos com endereco -->
      <p *ngIf="atendimento?.endereco != null" class="imprimir-dados-texto">
        <span *ngIf="atendimento?.endereco?.logradouro">
          <strong>Endereço: </strong>{{atendimento?.endereco?.logradouro}}, {{atendimento?.endereco?.numeroLogradouro}}
        </span>
        <br>
        <span *ngIf="atendimento?.endereco?.bairro">
          <strong>Bairro: </strong>{{atendimento?.endereco?.bairro}}
        </span>
        <br>
        <span *ngIf="atendimento?.endereco?.complemento">
          <strong>Complemento: </strong>{{atendimento?.endereco?.complemento}}
        </span>
      </p>
        
      <hr class="margem-lg">

      <div class="pedido-imprimir" *ngFor="let pedido of atendimento.pedidos">
        <div class="pedido-imprimir-title">
          <p class="pedido-id">
            <span>#{{pedido.idPedido}}</span>&nbsp;
          </p>
          <p class="pedido-qtd">
            <span>({{pedido.quantidade}})&nbsp;x&nbsp;</span>&nbsp;
          </p>
          <p class="pedido-produto">
            <span><strong>{{pedido.produto.produto}}</strong></span>&nbsp;
          </p>
          <p class="pedido-valor">
            <strong>{{pedido?.valor| currency:'BRL':'symbol-narrow':'1.2-2'}}</strong>
          </p>
        </div>
        <p class="pedido-obs" *ngIf="pedido.obs?.length>0">***&nbsp;{{pedido.obs}}&nbsp;***</p>
        <div class="pedido-imprimir-filhos" *ngFor="let pedidoFilho of pedido.pedidosFilho">
          <p class="pedido-id"></p>
          <p class="pedido-qtd">
            <span>({{pedidoFilho.quantidade}})&nbsp;x&nbsp;</span>&nbsp;
          </p>
          <p class="pedido-produto">
            <span>{{pedidoFilho.produto.produto}}</span>&nbsp;
          </p>
          <p class="pedido-valor">
            {{pedidoFilho?.valor| currency:'BRL':'symbol-narrow':'1.2-2'}}
          </p>
        </div>
      </div>

      <hr class="margem-sm" *ngIf="atendimento?.tipoAtendimento!='DEMANDA'">
        <p class="imprimir-dados-texto" *ngIf="atendimento?.obs"> Observação: <b *ngIf="atendimento?.obs">{{atendimento?.obs}}</b> <span
            *ngIf="!atendimento?.obs"> (sem observação) </span> </p>
      <hr class="margem-sm" *ngIf="atendimento?.obs">
      
      <p class="margem-sm imprimir-dados-texto-valores" *ngIf="atendimento?.tipoPagamentoEnum"> <span>Método de pagamento: <b>{{atendimento?.tipoPagamentoEnum}}  </b> </span>
        <span
          *ngIf="atendimento?.tipoPagamentoEnum ==='DINHEIRO' && (atendimento?.valorTroco!==0.00 && atendimento?.valorTroco )">&nbsp;-&nbsp;Troco
          para: <b>{{atendimento?.valorTroco | currency:'BRL':'symbol-narrow':'1.2-2' }}</b></span>
        <span
          *ngIf="atendimento?.tipoPagamentoEnum ==='DINHEIRO'&& (atendimento?.valorTroco===0.00|| !atendimento?.valorTroco)">&nbsp;-&nbsp;
          <b> Não precisa de troco </b></span>
      </p>
      
      <p class="margem-lg imprimir-dados-texto-valores">
        Subtotal: &nbsp;{{atendimento.valor | currency: 'BRL':'symbol-narrow':'1.2-2'}}
      </p>
      <p *ngIf="atendimento?.valorFrete > 0" class="imprimir-dados-texto-valores">
        Entrega: &nbsp;{{atendimento.valorFrete| currency: 'BRL':'symbol-narrow':'1.2-2'}}
      </p>
      <p *ngIf="atendimento?.valorDesconto > 0" class="imprimir-dados-texto-valores">
        Desconto: &nbsp;{{atendimento.valorDesconto| currency: 'BRL':'symbol-narrow':'1.2-2'}}
      </p>
      <p *ngIf="atendimento?.valorGorjeta > 0" class="imprimir-dados-texto-valores">
        Gorgeta: &nbsp;{{atendimento.valorGorjeta| currency: 'BRL':'symbol-narrow':'1.2-2'}}
      </p>
      <p *ngIf="atendimento?.valorCover > 0" class="imprimir-dados-texto-valores">
        Cover: &nbsp;{{atendimento.valorCover | currency: 'BRL':'symbol-narrow':'1.2-2'}}
      </p>
      <p *ngIf="atendimento?.valorAdicional> 0" class="imprimir-dados-texto-valores">
        Adicional: &nbsp;{{atendimento.valorAdicional | currency: 'BRL':'symbol-narrow':'1.2-2'}}
      </p>
      <p class="imprimir-dados-texto-valores">
        <b>Valor Total: &nbsp;{{atendimento.valorTotal | currency: 'BRL':'symbol-narrow':'1.2-2'}}</b>
      </p>

      <hr>

      <p class="rodape-imprimir">
        <b>CARDMENU - Seu pedido em boas mãos</b>
      </p>

      <hr>

      <p class="rodape-imprimir rodape-aviso"> 
        <small>
        DOCUMENTO AUXILIAR DE VENDA - NÃO É DOCUMENTO FISCAL NÃO É VÁLIDO COMO RECIBO E COMO GARANTIA DE
        MERCADORIA - NÃO COMPROVA PAGAMENTO
        </small>
      </p>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button (click)="p()"
    class="btn btn-primary">
    Imprimir&nbsp;
    <i class="fal fa-print"></i>
  </button>
</div>


  